import React from 'react'
import { FaInstagram, FaFacebookF, FaTwitter } from 'react-icons/fa'
import { Link } from 'gatsby'
// import { useIntl, Link } from "gatsby-plugin-intl"

const MobNav = ( ) => {
  // const intl = useIntl()
  return(
  <div className="nav hide-on-large-only">
    <input type="checkbox" className="nav__checkbox" id="nav-toggle" />
    <label for="nav-toggle" className="nav__button">
        <span className="nav__icon">&nbsp;</span>
    </label>

    <div className="nav__bg">&nbsp;</div>

    <div className="nav__nav" id="nav">
        <ul className="nav__list">
            <li className="nav__item">
                <Link to="#home" className="nav__link">Accueil</Link>
            </li>
            <li className="nav__item">
                <Link fade to="/about" className="nav__link">A propos</Link>
            </li>
            <li className="nav__item">
                <Link fade to="/services" onclick="uncheck" className="nav__link">Services</Link>
            </li>                    
            <li className="nav__item">
                <Link fade to="/portfolio" onclick="uncheck" className="nav__link">Portfolio</Link>
            </li>                                   
            <li className="nav__item">
                <Link fade to="/contact" className="nav__link">Contact</Link>
            </li>
            <li className="nav__item">
                <Link fade to="/app" className="nav__link">Allô doc</Link>
            </li> 
        </ul>
        <ul className="nav__socials left">
          <li><a className="color0" href="https://web.facebook.com/villasantehaiti" target="_blank" rel="noopener noreferrer"><FaFacebookF size="2em" /></a></li>
          <li><a className="color0" href="https://web.facebook.com/villasantehaiti" target="_blank" rel="noopener noreferrer"><FaInstagram size="2em" /></a></li>
        </ul>
    </div>
  </div>
)}

export default MobNav