import React, {useRef, useEffect} from "react";
import { Link } from "gatsby"
import Image from './image'
import { gsap } from 'gsap'
import { SlowMo } from 'gsap/EasePack'
import MobNav from './mobilenav'
gsap.registerPlugin(SlowMo)


const Nav = (props) => {

  let navEl = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  let titleEl = useRef(null)
  let bgEl = useRef(null)

  useEffect(() => {
    gsap.to(bgEl, {opacity: 1, duration: 1.2, y: 20, ease: "slow(0.1, 0.4, false)"})
    gsap.to(titleEl, {opacity: 1, duration: 1.2, x: 20, ease: "slow(0.7, 0.4, false)", delay: 1})
    gsap.to(navEl, {opacity: 1, duration: .8, y: 20, ease: "back", delay: 1.2, stagger: 0.1})
  })


  return(
  // <div className="navbar-fixed">
    <>
    <nav className="transparent"> 
      <svg ref={el => bgEl = el} id="navbg" width="1092" height="146" viewBox="0 0 1092 146" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M331.853 142.707C127.246 163.812 0 78.4251 0 30.2381C0 -17.9488 59.184 -42.7971 202.917 -73.8995C346.649 -105.002 517.86 -88.7624 598.182 -88.7624C733.459 -81.8199 1013.32 -54.4605 1050.52 -43.3525C1097.02 -29.4674 1151.98 30.2381 1097.02 73.2817C1025.15 116.325 826.463 155.203 708.095 126.045C589.727 96.8863 433.312 120.491 331.853 142.707Z" fill="#FFC107"/>
      </svg>
      <div className="nav-wrapper">
        <div className="container1">
          <Link to="/" className="brand-logo navbar-brand">
            <Image              
            />
            <h3 className="brand-name" ref={el => titleEl = el}>Villa Sant&eacute;</h3>
          </Link>
          <ul  className="right hide-on-med-and-down color-1-text">
            
            <li  className="hidden">
              <Link to="/"></Link>
            </li>
            <li className="navEl" ref={el => navEl[0] = el}><Link className="center-align" to="/">Accueil</Link></li>
            <li className="navEl" ref={el => navEl[1] = el}>
              <Link className="center-align" to="/about">A propos</Link>
            </li>
            <li className="navEl" ref={el => navEl[2] = el}>
              <Link className="center-align" to="/services">Services</Link>
            </li>
            <li className="navEl" ref={el => navEl[3] = el}>
              <Link className="center-align" to="/portfolio">Portfolio</Link>
            </li>
            {/* <li ref={el => navEl[3] = el}><Link className="center-align" to="/media">Media</Link></li> */}
            <li className="navEl" ref={el => navEl[4] = el}>
              <Link className="center-align" to="/contact">Contact</Link>
            </li>     
            <li className="navEl" ref={el => navEl[5] = el}>
              <Link className="center-align villatalk" to="/app">Allô doc</Link>             
            </li>       
          </ul>
          
        </div>
      </div>
    </nav>
    <MobNav />
    </>
  // </div>
)};
export default Nav;