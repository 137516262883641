import React from "react";
import {Row, Col, Icon} from "react-materialize"
import { Link } from "gatsby"
import Image from './image2'
import { FaLinkedinIn, FaFacebookF, FaTwitter } from 'react-icons/fa';



const Footer = () => (

  <footer >
    <div className="page-footer color-5">
    <div className="container1">
      <Row>
        <Col l={4} m={12} s={12} className="hide-on-med-and-down">
        {/* <Link to="/" ><Image className="footer-logo" /></Link> */}
        <Link to="/" >
          <h3 className="footer-title">Villa Sant&eacute;</h3>
          <Image mystyle="footer-image" />
        </Link>
        </Col>
        <Col l={4} m={12} s={12} className="center hide-on-large-only">
        {/* <Link to="/" ><Image className="footer-logo" /></Link> */}
        <Link to="/" >
          <h3 className="footer-title">Villa Sant&eacute;</h3>
          <Image mystyle="footer-image" />
        </Link>
        </Col>
        <Col l={3} m={4} s={4}>
          <h3 className="footer-list-head">Pages</h3>
          <ul className="footer-list">
            <li><Link className="" to="/">Accueil</Link></li>
            <li><Link className="" to="/about">A propos</Link></li>
            <li><Link className="" to="/services">Services</Link></li>
            <li><Link className="" to="/portfolio">Portfolio</Link></li>
            {/* <li><Link className="" to="/media">Media</Link></li> */}
            <li><Link className="" to="/contact">Contact</Link></li>
            <li><Link className="" to="/app">Allô doc</Link></li>
            <li><Link className="" to="/terms">Termes et Conditions</Link></li>
          </ul>
        </Col>
        <Col l={5} m={8} s={8}>
          <h3 className="footer-list-head">Contact</h3>
            <ul className="socials footer-list">
              <li><a className="" href="https://web.facebook.com/villasantehaiti/"><FaFacebookF /></a></li>
              <li><a className="" href="https://web.facebook.com/villasantehaiti/"><FaTwitter /></a></li>
              <li><a className="" href="https://web.facebook.com/villasantehaiti/"><FaLinkedinIn /></a></li>
              {/* <li><Link className="grey-text text-lighten-3" to="/contact">Contact</Link></li> */}
            </ul>
            <ul className="footer-list contact">
              <li><Icon> email </Icon> : info@villasantehaiti.com</li>
              <li><Icon> phone </Icon> : +50937262329, +50934581176</li>
              <li><Icon> place </Icon> : 15, angle rues Métellus et Borno (entrée de Montagne Noire), PV, Haiti</li>
            </ul>
        </Col>
      </Row>
    </div>
    <div className="footer-copyright">
      {/* <div className="container1"> */}
      © 2019, Designed by
      <a className="grey-text text-lighten-4 right" href="https://bizhightech.com/">BizHighTech</a>
      {/* </div> */}
    </div>
    </div>
  </footer>

)

export default Footer